// components/LanguageSelect.tsx
import { useState, useEffect, useRef } from 'react';
import { SlGlobe } from 'react-icons/sl';
import { useRouter } from 'next/router';
// import { useRouter , usePathname} from '@/navigation';

import { useMediaQuery } from 'react-responsive';

//redux
import { useAppSelector } from '@/src/hooks/useSelector';
import { useAppDispatch } from '@/src/hooks/useDispatch';
import { setLanguage, updateLanguage } from '@/store/slices/settingsSlice';


export type LanguageChoices= 'en' | 'jp'

interface LanguageDropdownProps {
  minimize?:boolean;
}

export const LanguageDropdown = ({
  minimize=false

}:LanguageDropdownProps) => {
  const router = useRouter();
  //const pathname = usePathname

  //responsive
  const smallScreen = useMediaQuery({ maxWidth:480 })

  //redux state
  const dispatch = useAppDispatch();
  const userData = useAppSelector(state => state.user).data

  //language from redux
  const language =  useAppSelector(state => state.settings).data.language
  

  // Handle language change and sync with router
  const handleLanguageChange = (newLanguage:LanguageChoices) => {
    if (newLanguage !== language) {
      //check if user logged or not
      let loggedIn = false
      if (userData.token) {
        loggedIn = true
      }
      // logged in: set to user settings in backend data
      if (loggedIn) {
        dispatch(updateLanguage({language: newLanguage}))
      // not logged in: only set state in redux
      } else {
        dispatch(setLanguage(newLanguage))
      }
      setDropdownOpen(false)
    }
  };


  //dropdown state
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };


  /**
   * CLICK OUTSIDE 
   */
  const handleClickOutside = (event: Event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener('click', handleClickOutside);
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);
  

  return (
    <div className="relative inline-block text-left" ref={dropdownRef}>
      <button
        type="button"
        className={`inline-flex items-center space-x-2 text-gray-600 focus:outline-none 
            p-2 rounded-full cursor-pointer
            ${isDropdownOpen ? 'bg-gray-300' : 'bg-white hover:bg-gray-100'}
        `}
        onClick={toggleDropdown}
      >
        <SlGlobe size={20} />
      </button>

      {isDropdownOpen && (
        <div className={`
          origin-top-right absolute right-0 mt-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 py-2
          ${isDropdownOpen ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-[-100%]'}
          transition-all duration-500 ease-in-out
        `}>
          <ul className="py-1 flex flex-col">
            <li 
              onClick={language!='en' ? () => handleLanguageChange('en') : undefined}
              className={`
                text-gray-700 hover:bg-gray-100 hover:text-gray-900
                block px-4 py-2 text-sm 
                ${language!='en' ? 'cursor-pointer' : 'font-bold'}
              `}>English
            </li>
            <li 
              onClick={language!='jp' ? () => handleLanguageChange('jp') : undefined}
              className={`
                text-gray-700 hover:bg-gray-100 hover:text-gray-900
                block px-4 py-2 text-sm 
                ${language!='jp' ? 'cursor-pointer' : 'font-bold'}
              `}>日本語
            </li>

          </ul>
        </div>
      )}
    </div>
  );
};


