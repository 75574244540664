//packages
import React,{useEffect, useState, useRef} from 'react';
import { useRouter } from 'next/router';

import { UserDrawerMenu } from '../menus/user-drawer-menu/UserDrawerMenu';

//hi icons
import { HiOutlineHome } from "react-icons/hi";
import { HiOutlineCalendar } from "react-icons/hi";
import { HiOutlineSearch } from "react-icons/hi";
import { HiOutlineHeart } from "react-icons/hi";
import { HiOutlineMenu } from "react-icons/hi";

//redux
import { useAppSelector } from '@/src/hooks/useSelector';

import { useMediaQuery } from 'react-responsive';


interface UserMobileNavFooterProps    {
}

export const UserMobileNavFooter = ({
}: UserMobileNavFooterProps) => {
    const router = useRouter();
    const currentUrl = router.asPath    
    const {action} = router.query
    //language translation settings
    const l = useAppSelector(state => state.settings).data.language

    //responsive
    const isBigScreen = useMediaQuery({ minWidth:1024 })


    const userData = useAppSelector(state => state.user).data
    const {token} = userData


    /** DRAWER MENU VISIBLE STATE */
    const [drawerMenuVisible, setDrawerMenuVisible] = useState(false)
    // hide drawer on route change
    useEffect(() => {
        const handleRouteChange = () => { setDrawerMenuVisible(false)};
        // Subscribe to route change events
        router.events.on('routeChangeStart', handleRouteChange);

        return () => {
            // Cleanup listener on unmount
            router.events.off('routeChangeStart', handleRouteChange);
        };
    }, [router]);


    /**
     * TEXT TRANSLATIONS
    */
    const text:any = {
        search:{
            en:'Search',
            jp:'検索'
        },
        following:{
            en:'Following',
            jp:'気に入り'
        },
        bookings:{
            en:'Bookings',
            jp:'予約'
        },
        menu:{
            en:'Menu',
            jp:'メニュー'
        },
    }

    const TabContainer = ({ onClick, isActive, children }:{
        onClick: () => void,
        isActive: boolean,
        children: JSX.Element | JSX.Element[]
    }) => {
        return (
            <div
                onClick={onClick} 
                className={`w-full h-full flex flex-col gap-1 items-center justify-center cursor-pointer pt-1
                ${isActive ? 'text-prime-orange-dark' : 'text-gray-500'}
            `}>
                {children}
            </div>
        )
    }

    return (
        <>
        <div className={`sticky bottom-0 bg-white w-full h-[70px] drop-shadow-sm flex items-center relative border-t`}>
            <nav className='flex flex-row h-full w-full justify-between items-center'>

                {/** NAV ITEMS */}
                <TabContainer
                    onClick={() => router.push('/')} 
                    isActive={router.pathname==='/'}
                >
                    <HiOutlineHome size={20} className=''/>
                    <p className='text-sm'>
                        Home
                    </p>
                </TabContainer>

                <TabContainer
                    onClick={() => router.push(token ? '/user/bookings' : '/signup')} 
                    isActive={currentUrl.includes('/user/bookings')}
                >
                    <HiOutlineCalendar size={20} className=''/>
                    <p className='text-sm'>
                        {text['bookings'][l]}
                    </p>
                </TabContainer>
                
                <TabContainer
                    onClick={() => router.push(token ? '/search-lessons' : '/signup')} 
                    isActive={currentUrl.includes('/search-lessons')}
                >
                    <HiOutlineSearch size={20} className=''/>
                    <p className='text-sm'>
                        Search
                    </p>
                </TabContainer>
                
                <TabContainer
                    onClick={() => router.push(token ? '/user/following' : '/signup')} 
                    isActive={currentUrl.includes('/user/following')}
                >
                    <HiOutlineHeart size={20} className=''/>
                    <p className='text-sm'>
                        Following
                    </p>
                </TabContainer>
                
                
                
                <TabContainer
                    // onClick={openMenuHandler} 
                    onClick={() => setDrawerMenuVisible(true)}
                    isActive={false}
                >
                    <HiOutlineMenu size={20} className=''/>
                    <p className='text-sm'>{text['menu'][l]}</p>
                </TabContainer>

            </nav>
        </div>
        <UserDrawerMenu 
            isVisible={drawerMenuVisible}
            hideDrawerCallback={() => setDrawerMenuVisible(false)}
            is_left={isBigScreen ? false : true}
        />
        
        </>
    )
}