import React from 'react'


import { UserHeader } from '@/src/components-v3/headers/UserHeader';
import { UserMobileNavFooter } from '@/src/components-v3/footers/UserMobileNavFooter';
import { UserFooterNew } from '@/src/components-new/footer/userFooter';

import { UserFeedbackWidget } from '@/src/components-etc/user-feedback-widget/UserFeedbackWidget';

interface UserMainLayoutProps {
    className?: string;
    children?: JSX.Element | JSX.Element[];
    pageTitle?: string;
    bgColor?: string;
    backHandler?: () => void;
}

export const UserMainLayout = ({
    className,
    children,
    pageTitle='',
    bgColor='bg-white',
    backHandler
}: UserMainLayoutProps) => {

    return (
        <div className={`flex flex-col w-screen ${bgColor}`}>
            <div className='flex flex-col min-h-screen w-full h-full'>
                <div className='w-full top-0 sticky z-[20]'> 
                    <UserHeader 
                        pageTitle={pageTitle}
                        backHandler={backHandler}
                    />
                </div>

                <div className={`w-full h-full overflow-y-scroll ${className}`}>
                    {children}
                </div>
            </div>

            <UserFooterNew />
            
            <div className='md:hidden w-full bottom-0 sticky'>
                <UserMobileNavFooter/>
            </div>
            <UserFeedbackWidget/>
        </div>
    )
}